import { Expose, Transform, Type } from 'class-transformer';

import { OptionChangeRequestModel } from './chenge-request.model';
import { ContactPersonModel } from './contact-persone.model';
import { FileModel } from './file.model';
import { OptionRuleModel } from './option-rules.model';
import { PreviewModel, ProductListItemModelCPB } from './product.model';
import { SelectedPackage } from './selected-package.model';
import { IBootstrapDate } from './time-line.model';
import { VendorModel } from './vendor.model';
import { EWishlistStatus, WishlistItem } from './wishlist.model';
import { EPriceOfferItemStatus } from './price-offer.model';
import { IReclamationProductPreview } from './reclamations.model';

export class PriceOfferModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'price' }) price!: number;
  @Expose({ name: 'price_currency' }) priceCurrency!: string;
  @Expose({ name: 'due_date' }) dueDate!: string;
  @Expose({ name: 'file_resources' }) @Type(() => FileModel) fileResourcesAttributes!: FileModel[];
  @Expose({ name: 'description' }) description!: string | null;
  @Expose({ name: 'is_offer_accepted' }) isOfferAccepted!: boolean;
  @Expose({ name: 'viewed_by_user' }) viewed!: boolean;
  @Expose({ name: 'status' }) status!: EPriceOfferItemStatus;

  @Expose({ name: 'category' }) category!: {
    id: number,
    name: string
  };
  @Expose({ name: 'layout_product_type' }) layoutProductType!: {
    id: number,
    name: string
  };

  @Expose({ name: 'layout_product_type_item' }) layoutProductTypeItem!: {
    id: number,
    title: string
    preview: IReclamationProductPreview
  };
  @Expose({ name: 'other_lpt_active_offer' }) otherLptActiveOffer!: boolean;
  
  
}

export interface IOptionStoreNavigationHistory {
  projectId: number;
  path: string;
}

export interface IConnectiveUnitInfos {
  projectId: number | null;
  unitTypeId: number | null;
  filterBy: string;
}

export enum EGeneralOptionTranslate {
  Settings = 'Entity.Settings',
  Rooms = 'Title.Rooms',
  Order = 'Entity.Order',
  Units = 'Title.Units',
}

export const layoutTypeCategoryTranslate = new Map<string, string>([
  ['Introduction', 'Title.Introduction'],
  ['Electro', 'Title.Electro'],
]);

export interface IConfigureOptionOriginal {
  dueDate?: IBootstrapDate;
  markup?: number;
  includeElectro: boolean;
  includeIntroduction: boolean;
  fixedDeadline?: boolean;
  standardProductPreselection: boolean;
  saveAsTemplate?: boolean;
  allowBankId?: boolean;
}

export interface IConfigureOptionForm {
  due_date?: Date | null | string;
  markup?: number | null;
  include_introduction: boolean;
  standard_product_preselection: boolean;
  enabled?: boolean;
  save_as_template?: boolean;
  allow_bank_id?: boolean;
  design_line?: boolean;
  cloned_layout_option?: any;
  layout_option_categories?: any;
}

export interface ILayoutTypeCategoryRequest {
  id: number;
  room_id: number;
  name: string;
  description: string;
  markup: string;
  enabled: boolean;
  room_type: string;
  room_name: string;
  room_size: string;
  room_celling_height: string;
  room_walls_area_total: string;
  due_date: string;
  contact_person: string;
}

export interface ILayoutTypeUpdate {
  layout_option_category: Partial<ILayoutTypeCategoryRequest>;
}

export enum ELayoutCategoryState {
  All = 'all',
  Enabled = 'enabled',
}

export class ConfigureOption {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'due_date' })
  dueDate!: string;
  @Expose({ name: 'markup' })
  markup!: number;

  @Expose({ name: 'need_republish' })
  needRepublish!: boolean;

  @Expose({ name: 'allow_bank_id' }) allowBankId!: boolean;

  @Expose({ name: 'include_introduction' })
  includeIntroduction!: boolean;
  @Expose({ name: 'include_electro' })
  includeElectro!: boolean;
  @Expose({ name: 'standard_product_preselection' })
  standardProductPreselection!: boolean;
  @Expose({ name: 'design_line' }) designLine!: boolean;

  @Expose({ name: 'enabled' })
  enabled!: boolean; // In the end after all changes we will update to true.
  @Expose({ name: 'merge_rooms' })
  mergeRooms!: boolean; // Not need right now.
  @Expose({ name: 'status' })
  status!: string; // Don't unde
}

export class LayoutProductTypesResponse {
  data!: {
    layout_product_types: LayoutProductType[];
    hide_no_standard_delivery_popup: boolean;
  };
}

export class LayoutProductTypeResponse {
  data!: LayoutProductType;
}

export class ProductForLayoutProductTypeRequest {
  @Expose({ name: 'productId' }) product_id!: number;
  @Expose() standard!: boolean;
}

export class LayoutGroup {
  @Expose({ name: 'count_products' }) countProducts!: number;
  @Expose() id!: number;
  @Expose() name!: string;
  @Expose()
  @Type(() => PreviewModel)
  @Transform(({ value }) => value?.filename?.w260?.url || '/assets/group-placeholder.svg', { toClassOnly: true })
  preview!: string;
  @Expose({ name: 'products' })
  @Type(() => ProductListItemModelCPB)
  products!: ProductListItemModelCPB[];
  @Expose() isStandardGroup: boolean | undefined;
  @Expose() isActiveGroup: boolean | undefined;
  @Expose() expired = false;
  @Expose() wishlistItemStatus?: EWishlistStatus;
  @Expose({ name: 'position' }) position!: number;

  groupPrice?: number;
  productTitle?: string;
}

export class LayoutProductType {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'expired' }) expired!: boolean;
  @Expose({ name: 'due_date' }) dueDate!: string;
  @Expose({ name: 'area_size' }) areaSize!: string | null;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'markup' }) markup!: null | string | undefined;
  @Expose({ name: 'custom_colors' }) customColors!: boolean;
  @Expose({ name: 'project_name' }) projectName!: string;
  @Expose({ name: 'layout_type_name' }) layoutTypeName!: string;
  @Expose({ name: 'layout_type_id' }) layoutTypeId!: number;
  @Expose({ name: 'show_custom_colors' }) showCustomColors!: boolean;
  @Expose({ name: 'mandatory' }) mandatory!: boolean;
  @Expose({ name: 'quantity' }) quantity!: null | number;
  @Expose({ name: 'show_quantity' }) showQuantity!: boolean;
  @Expose({ name: 'show_area_size' }) showAreaSize!: boolean;
  @Expose({ name: 'product_type_id' }) productTypeId!: number;
  @Expose({ name: 'grouped' }) grouped!: boolean;
  @Expose({ name: 'description' }) description!: string | null;
  @Expose({ name: 'emptyGroups' }) emptyGroups!: boolean;
  @Expose({ name: 'hasMixedProducts' }) hasMixedProducts!: boolean;
  @Expose({ name: 'wishlist_item_status' }) wishlistItemStatus!: EWishlistStatus;
  @Expose({ name: 'wishlist_item_signing_status' }) wishlistItemSigningStatus!: EWishlistStatus;
  @Expose({ name: 'blocked' }) blocked!: boolean;
  @Expose({ name: 'wishlist_item_auto_completed' }) wishlistItemAutoCompleted!: boolean;
  @Expose({ name: 'extra_amount_enabled' }) extraAmountEnabled!: boolean;
  @Expose({ name: 'max_extra_amount' }) maxExtraAmount!: number | null;
  @Expose({ name: 'wishlist_item_extra_amount' }) wishlistItemExtraAmount!: number | null;
  @Expose({ name: 'digital_showroom_id' }) digitalShowroomId!: string;
  selectedShowroomVariant!: string;

  extraTotalAmount!: number;
  extraMaxTotalAmount!: number;

  @Expose({ name: 'products' })
  @Type(() => ProductListItemModelCPB)
  products!: ProductListItemModelCPB[];
  @Expose({ name: 'groups' })
  @Type(() => LayoutGroup)
  @Transform(({ value }) => {
    return value || [];
  })
  groups!: LayoutGroup[];

  @Expose({ name: 'preview' })
  preview!: string;

  standardProduct?: ProductListItemModelCPB;
  rules?: OptionRuleModel[];

  blockingProducts: string[] = [];

  constructor(model?: Partial<LayoutProductType>) {
    if (!model) {
      return;
    }
    Object.assign(this, model);
  }

  product: ProductListItemModelCPB | undefined;
  selected = true;
}

export class FirmLayoutType {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'layout_type_id' }) layoutTypeId!: number;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'project_name' }) projectName!: string;
  @Expose({ name: 'layout_type_name' }) layoutTypeName!: string;
}

export interface IClientLayoutTypeCategory {
  id: number;
  name: string;
  position: number;
  route: string | undefined;
  is_introduction: boolean;
}

export class OptionCategoryProduct {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'products' })
  @Type(() => ProductListItemModelCPB)
  products!: ProductListItemModelCPB[];
}

export class ExpiredCategory {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'description' }) description!: string;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'preview_in_progress' }) previewInProgress!: boolean;

  @Expose({ name: 'products' })
  @Type(() => ProductListItemModelCPB)
  products!: ProductListItemModelCPB[];
}

export class ExpiredCategories {
  @Expose({ name: 'categories' })
  @Type(() => ExpiredCategory)
  categories!: ExpiredCategory[];
}

export class ClientLayoutTypeCategoryData {
  @Expose({ name: 'layout_option_categories' })
  @Type(() => ClientLayoutTypeCategory)
  layoutOptionCategories!: ClientLayoutTypeCategory[];

  @Expose({ name: 'wishlist_has_completed_item' }) wishlistHasCompletedItem!: boolean;
  @Expose({ name: 'all_completed' }) allCompleted!: boolean;
  @Expose({ name: 'digital_showroom_completed' }) digitalShowroomCompleted!: boolean;
  @Expose({ name: 'option_design_line' }) optionDesignLine!: boolean;
  @Expose({ name: 'option_has_packages' }) optionHasPackages!: boolean;
  @Expose({ name: 'selected_package' }) selectedPackage!: {
    id: number;
    name: string;
  };
}

export class ClientLayoutTypeCategory {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'position' })
  position!: number;
  @Expose({ name: 'route' })
  route!: string | undefined;
  @Expose({ name: 'is_introduction' })
  isIntroduction!: boolean;

  @Expose({ name: 'digital_showroom' })
  digitalShowroom!: boolean;
  @Expose({ name: 'digital_showroom_id' })
  digitalShowroomId!: string;
}

class ContactPerson {
  @Expose({ name: 'company_id' })
  companyId!: number;
  @Expose({ name: 'company_title' })
  companyTitle!: string;
  @Expose({ name: 'email' })
  email!: string;
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'phone' })
  phone!: string;
}

export class CategoryDetails {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'is_introduction' })
  isIntroduction!: boolean;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'description' })
  description!: string;
  @Expose({ name: 'due_date' })
  dueDate!: string;
  @Expose({ name: 'contact_person' })
  @Type(() => ContactPerson)
  contactPerson!: ContactPerson | null;

  @Expose({ name: 'has_different_due_date' })
  hasDifferentDuedate!: boolean;
}

export class LayoutTypeCategory {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'room_id' })
  roomId!: number;
  @Expose({ name: 'is_introduction' })
  isIntroduction!: boolean;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'description' })
  description!: string;
  @Expose({ name: 'markup' })
  markup!: number;
  @Expose({ name: 'enabled' })
  enabled!: boolean;
  @Expose({ name: 'room_type' })
  roomType!: string;
  @Expose({ name: 'room_name' })
  roomName!: string;
  @Expose({ name: 'room_size' })
  roomSize!: string;
  @Expose({ name: 'room_floor_number' })
  roomFloorNumber!: number | null;
  @Expose({ name: 'room_celling_height' })
  roomCellingHeight!: string;
  @Expose({ name: 'room_walls_area_total' })
  roomWallsAreaTotal!: string;
  @Expose({ name: 'due_date' })
  dueDate!: string;
  @Expose({ name: 'manager_contact_id' })
  managerContactId?: number;
  @Expose({ name: 'contact_person' })
  @Type(() => ContactPersonModel)
  contactPerson!: ContactPersonModel;
  @Expose({ name: 'selected' })
  selected = false;

  @Expose({ name: 'route' })
  route!: string;

  @Expose({ name: 'has_products' })
  hasProducts = false;

  @Expose({ name: 'hovered' })
  hovered = false;

  @Expose({ name: 'published' })
  published = false;
  @Expose({ name: 'is_custom' })
  isCustom!: boolean;

  // eslint-disable-next-line sonarjs/no-identical-functions
  constructor(model?: Partial<LayoutTypeCategory>) {
    if (!model) {
      return;
    }
    Object.assign(this, model);
  }
}

export interface IOptionsCategory {
  id: number;
  layout_product_types: LayoutProductType[];
  layout_type_id: number;
  markup: string | undefined;
  layout_type_name: string;
  project_name: string;
}

export class OptionsCategory {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'layout_product_types' })
  @Type(() => LayoutProductType)
  layoutProductTypes!: LayoutProductType[];
  @Expose({ name: 'layout_type_id' })
  layoutTypeId!: number;
  @Expose({ name: 'markup' })
  markup!: string | undefined;

  @Expose({ name: 'layout_type_name' })
  layoutTypeName!: string;
  @Expose({ name: 'project_name' })
  projectName!: string;
}

export class UnitSummary {
  @Expose({ name: 'price_currency' }) priceCurrency!: string;
  @Expose({ name: 'total_price' }) totalPrice!: string;
  @Expose({ name: 'unit_identifier' }) unitIdentifier!: string;
  @Expose({ name: 'layout_option_id' }) layoutOptionId!: number;
  @Expose({ name: 'layout_type_id' }) layoutTypeId!: number;
  @Expose({ name: 'categories' })
  @Type(() => Category)
  categories!: Category[];
}

export class Category {
  @Expose({ name: 'category_type' }) categoryType!: string;
  @Expose({ name: 'due_date' }) dueDate!: string;
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'price_currency' }) priceCurrency!: string;
  @Expose({ name: 'total_price' }) totalPrice!: number;
  @Expose({ name: 'products' })
  @Type(() => CategoryProduct)
  products!: CategoryProduct[];
  @Expose({ name: 'room_size' }) roomSize!: string;
  @Expose({ name: 'room_type' }) roomType!: string;
  @Expose({ name: 'room_walls_area_total' }) roomWallsAreaTotal!: number;
  selected = true;
}

export class CategoryProduct {
  @Expose({ name: 'currency' }) currency!: string;
  @Expose({ name: 'price' }) price!: string;
  @Expose({ name: 'product_type_name' }) productTypeName!: string;
  @Expose({ name: 'quantity' }) quantity!: string | null;
  @Expose({ name: 'standard' }) standard!: boolean;
  @Expose({ name: 'title' }) title!: string;
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'layout_product_type_id' }) layoutProductTypeId!: number;
  @Expose({ name: 'layout_product_type_item_id' }) layoutProductItemId!: number;
  @Expose({ name: 'product_type_id' }) productTypeId!: number;
  @Expose({ name: 'product_type_due_date' }) productTypeDueDate!: string | Date;
  @Expose({ name: 'new_item' }) newItem!: boolean;
  @Expose({ name: 'status' }) status!: string;
  @Expose({ name: 'allow_free_text' }) allowFreeText!: boolean;
  @Expose({ name: 'free_text' }) freeText!: string | null;
  @Expose({ name: 'price_offer_id' }) priceOfferId!: number | null;
  @Expose({ name: 'price_offer' })
  @Type(() => PriceOfferModel)
  priceOffer!: PriceOfferModel | null;
  @Expose({ name: 'price_requested' }) priceRequested!: boolean;

  @Expose()
  @Type(() => PreviewModel)
  @Transform(({ value }) => value?.filename?.w260?.url || 'assets/preview-v2.svg', { toClassOnly: true })
  preview!: string;

  @Expose({ name: 'auto_completed' }) autoCompleted!: boolean;
  @Expose({ name: 'is_wall_square' }) isWallSquare!: boolean;
  @Expose({ name: 'price_type' }) priceType!: string;
  @Expose({ name: 'area_size' }) areaSize!: string | null;
  @Expose({ name: 'signing_status' }) signingStatus!: string;
  @Expose({ name: 'extra_amount' }) extraAmount!: number | null;
  @Expose({ name: 'replaced_product' }) replacedProduct!: boolean;
  @Expose({ name: 'product_type' }) productType?: { id: number; title: string };

  selected = true;
  showDropdown = false;
  dateFocused = false;

  changedValue = false;
}

export interface INewCustomProduct {
  price: number;
  name: string;
  files: File[] | FileModel[] | [];
  companyId?: number;
  description: string;
  productCategoryId?: number;
}

export class CustomProductModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'area_size' }) areaSize!: number;
  @Expose({ name: 'file_resources' }) @Type(() => FileModel) fileResourcesAttributes!: FileModel[];
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'price' }) price!: number;
  @Expose({ name: 'product_type_due_date' }) productTypeDueDate!: number | null;
  @Expose({ name: 'quantity' }) quantity!: number;
  @Expose({ name: 'status' }) status!: string;
  @Expose({ name: 'signing_status' }) signingStatus!: string;
  @Expose({ name: 'vendor' }) vendor!: VendorModel;
  @Expose({ name: 'description' }) description!: string;
  @Expose({ name: 'product_category' }) productCategory!: {
    id: number;
    name: string;
  };

  asInput = false;
  changedValue = false;
  selected = true;
}

export class CategoryPhase {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'due_date_for_unit' }) dueDate!: string;
  @Expose({ name: 'start_date_for_unit' }) startDate!: string;
}

export class UnitsLayoutOptionCategoryModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'start_date' }) startDate!: string;
  @Expose({ name: 'end_date' }) endDate!: string;
  layoutOptionCategoryId?: number;
}

export class ClientCategory {
  @Expose({ name: 'category_type' }) categoryType!: string;
  @Expose({ name: 'start_date' }) startDate!: string | Date;
  @Expose({ name: 'due_date' }) dueDate!: string | Date;
  @Expose({ name: 'status' }) status!: string;
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'price_currency' }) priceCurrency!: string;
  @Expose({ name: 'total_price' }) totalPrice!: string;
  @Expose({ name: 'products' })
  @Type(() => CategoryProduct)
  products!: CategoryProduct[];
  @Expose({ name: 'room_size' }) roomSize!: string;
  @Expose({ name: 'room_type' }) roomType!: string;
  @Expose({ name: 'room_walls_area_total' }) roomWallsAreaTotal!: number;
  @Expose({ name: 'total_in_progress_price' }) totalInProgressPrice!: number;
  @Expose({ name: 'expired' }) expired!: boolean;
  @Expose({ name: 'mandatory_products' })
  @Type(() => CategoryProduct)
  mandatoryProducts!: CategoryProduct[];

  @Expose({ name: 'optional_products' })
  @Type(() => CategoryProduct)
  optionalProducts!: CategoryProduct[];
  @Expose({ name: 'custom_products' })
  @Type(() => CustomProductModel)
  customProducts!: CustomProductModel[];
  @Expose({ name: 'phase' })
  @Type(() => CategoryPhase)
  phase!: CategoryPhase;
  dateFocused = false;
  @Expose({ name: 'units_layout_option_category' })
  @Type(() => UnitsLayoutOptionCategoryModel)
  unitsLayoutOptionCategory!: UnitsLayoutOptionCategoryModel | null;
}

export class NotCompletedCategory {
  @Expose({ name: 'due_date' }) dueDate!: string;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'not_completed_items_count' }) notCompletedItemsCount!: number[];
  @Expose({ name: 'status' }) status!: string;
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'not_signed_items_count' }) notSignedItemsCount!: number;
  @Expose({ name: 'expired' }) expired!: boolean;
  @Expose({ name: 'digital_showroom' }) digitalShowroom!: boolean;
  @Expose({ name: 'digital_showroom_id' }) digitalShowroomId!: string;
}

export class PriceOffersModel {
  @Expose({ name: 'accepted' }) 
  @Type(() => PriceOfferModel)
  accepted!: PriceOfferModel[];

  @Expose({ name: 'offered' }) 
  @Type(() => PriceOfferModel)
  offered!: PriceOfferModel[];

  @Expose({ name: 'requested' }) 
  @Type(() => PriceOfferModel)
  requested!: PriceOfferModel[];

  @Expose({ name: 'declined' }) 
  @Type(() => PriceOfferModel)
  declined!: PriceOfferModel[];
}

export class OptionsDashboard {
  @Expose({ name: 'total_price' }) totalPrice!: string;
  @Expose({ name: 'all_completed_items_price' }) allCompletedItemsPrice!: number;
  @Expose({ name: 'closest_due_date' }) closestDueDate!: number;
  @Expose({ name: 'mandatory_items_count' }) mandatoryItemsCount!: number;
  @Expose({ name: 'option_progress' }) optionProgress!: number;
  @Expose({ name: 'option_start_date' }) optionStartDate!: string | null;

  @Expose({ name: 'not_completed_categories' })
  @Type(() => NotCompletedCategory)
  notCompletedCategories!: NotCompletedCategory[];

  @Expose({ name: 'submitted_mandatory_items_count' }) submittedMandatoryItemsCount!: number;
  @Expose({ name: 'categories_closest_due_date' }) categoriesClosestDueDate!: { name: string }[];

  @Expose({ name: 'not_completed_price_requested_items' })
  @Type(() => WishlistItem)
  notCompletedPriceRequestedItems!: WishlistItem[];

  @Expose({ name: 'all_in_progress_items_price' }) allInProgressItemsPrice!: number;
  @Expose({ name: 'completed_items' }) @Type(() => WishlistItem) completedItems!: WishlistItem[];
  @Expose({ name: 'not_completed_items' }) @Type(() => WishlistItem) notCompletedItems!: WishlistItem[];

  @Expose({ name: 'status' }) status!: string;
  @Expose({ name: 'price_currency' }) priceCurrency!: string;
  @Expose({ name: 'has_completed_item' }) hasCompletedItem!: boolean;
  @Expose({ name: 'has_expired_not_completed_category' }) hasExpiredNotCompletedCategory!: boolean;
  @Expose({ name: 'all_categories_expired' }) allCategoriesExpired!: boolean;
  @Expose({ name: 'submitted_once' }) submittedOnce!: boolean;
  @Expose({ name: 'has_expired_product_categories' }) hasExpiredProductCategories!: boolean;

  @Expose({ name: 'selected_package' })
  @Type(() => SelectedPackage)
  selectedPackage!: SelectedPackage | null;

  @Expose({ name: 'price_offers' }) 
  @Type(() => PriceOffersModel)
  priceOffers!: PriceOffersModel;
  separatedPriceRequestedItems!: WishlistItem[];

  @Expose({ name: 'categories' })
  @Type(() => Category)
  categories!: Category[];

  @Expose({ name: 'client_categories' })
  @Type(() => ClientCategory)
  clientCategories!: ClientCategory[];

  @Expose({ name: 'in_progress_items_count' }) inProgressItemsCount!: number;
  @Expose({ name: 'new_items_count' }) newItemsCount!: number;

  @Expose({ name: 'needed_offers_count' }) neededOffersCount!: number;
  @Expose({ name: 'without_answers_offers_count' }) withoutAnswersOffersCount!: number;
  @Expose({ name: 'allow_bank_id' }) allowBankId!: boolean;
  @Expose({ name: 'change_requests' })
  @Type(() => OptionChangeRequestModel)
  changeRequests!: OptionChangeRequestModel[];
  @Expose({ name: 'all_change_requests_price' })
  allChangeRequestsPrice!: number;

  @Expose({ name: 'has_introduction' }) hasIntroduction!: boolean;
  @Expose({ name: 'has_started_categories' }) hasStartedCategories!: boolean;

  @Expose({ name: 'total_layout_product_types_count' }) totalLayoutProductTypesCount!: number;
  @Expose({ name: 'total_submitted_layout_product_types_count' }) totalSubmittedLayoutProductTypesCount!: number;
}

export class PopularProducts {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'currency' }) currency!: string;
  @Type(() => PreviewModel)
  @Transform(({ value }) => value?.filename?.w260?.url || 'assets/no_images.png', { toClassOnly: true })
  preview!: string;
  @Expose({ name: 'price' }) price!: string;
  @Expose({ name: 'title' }) title!: string;
}
